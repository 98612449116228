<template>
  <div class="root">

    <div>
      test01文档流
    </div>

    <div>
      <lotteryRounds
        class="lotteryRounds"
        :records="lotteryRoundsRecord"
        :singlePlayTime="speed"
        @locationChange="resLocationChange"
      ></lotteryRounds>
    </div>

    <!-- <div style="border: 1px solid greenyellow;">
      {{ `location:${resLocation.y}` }}
    </div> -->

    <div>
      test01文档流
    </div>

  </div>
</template>

<script>
import lotteryRounds from "@/components/pkHome/lotteryRounds.vue";

export default {
  data() {
    return {
      lotteryRoundsRecord: [
        {
          boxId: 3,
          boxName: "测试1-3",
          boxPrice: 90,
          fightId: 827,
          fightRoundNumber: 1,
          id: 5620,
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
          isShow: 0,
          ornamenName: "AK-47 | 二西莫夫",
          ornamentsId: 3609,
          ornamentsLevelId: 1,
          ornamentsPrice: 148.85,
          source: "0",
          status: "0",
          userId: 32,
          boxInfo: {
            boxId: 3,
            boxImg01: "",
            boxImg02: "",
            number: 3,
            ornaments: [
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 28,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBNCB8IEFzaWltb3YgKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A4 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3858,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 888.49,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 22,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3606,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 784.18,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 30,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoV2VsbC1Xb3JuKQ==.png",
                itemName: "AWP | 二西莫夫 (破损不堪)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 4177,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 557.22,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 26,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoQmF0dGxlLVNjYXJyZWQp.png",
                itemName: "AWP | 二西莫夫 (战痕累累)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3854,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 429.61,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 23,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChNaW5pbWFsIFdlYXIp.png",
                itemName: "AK-47 | 二西莫夫 (略有磨损)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3713,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 271.31,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 29,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDkwIHwgQXNpaW1vdiAoTWluaW1hbCBXZWFyKQ==.png",
                itemName: "P90 | 二西莫夫 (略有磨损)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 1985,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 98.74,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 25,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDkwIHwgQXNpaW1vdiAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "P90 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 1912,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 47.23,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 24,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDI1MCB8IEFzaWltb3YgKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "P250 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 9059,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 17.69,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 27,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDI1MCB8IEFzaWltb3YgKEJhdHRsZS1TY2FycmVkKQ==.png",
                itemName: "P250 | 二西莫夫 (战痕累累)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 9270,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 9.58,
              },
            ],
          },
        },
        {
          boxId: 3,
          boxName: "测试1-3",
          boxPrice: 90,
          fightId: 827,
          fightRoundNumber: 2,
          id: 5622,
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
          isShow: 0,
          ornamenName: "AK-47 | 二西莫夫",
          ornamentsId: 3609,
          ornamentsLevelId: 1,
          ornamentsPrice: 148.85,
          source: "0",
          status: "0",
          userId: 32,
          boxInfo: {
            boxId: 3,
            boxImg01: "",
            boxImg02: "",
            number: 3,
            ornaments: [
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 28,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBNCB8IEFzaWltb3YgKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A4 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3858,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 888.49,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 22,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3606,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 784.18,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 30,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoV2VsbC1Xb3JuKQ==.png",
                itemName: "AWP | 二西莫夫 (破损不堪)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 4177,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 557.22,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 26,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoQmF0dGxlLVNjYXJyZWQp.png",
                itemName: "AWP | 二西莫夫 (战痕累累)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3854,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 429.61,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 23,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChNaW5pbWFsIFdlYXIp.png",
                itemName: "AK-47 | 二西莫夫 (略有磨损)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3713,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 271.31,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 29,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDkwIHwgQXNpaW1vdiAoTWluaW1hbCBXZWFyKQ==.png",
                itemName: "P90 | 二西莫夫 (略有磨损)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 1985,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 98.74,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 25,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDkwIHwgQXNpaW1vdiAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "P90 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 1912,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 47.23,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 24,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDI1MCB8IEFzaWltb3YgKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "P250 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 9059,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 17.69,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 27,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDI1MCB8IEFzaWltb3YgKEJhdHRsZS1TY2FycmVkKQ==.png",
                itemName: "P250 | 二西莫夫 (战痕累累)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 9270,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 9.58,
              },
            ],
          },
        },
        {
          boxId: 3,
          boxName: "测试1-3",
          boxPrice: 90,
          fightId: 827,
          fightRoundNumber: 3,
          id: 5624,
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
          isShow: 0,
          ornamenName: "AK-47 | 二西莫夫",
          ornamentsId: 3609,
          ornamentsLevelId: 1,
          ornamentsPrice: 148.85,
          source: "0",
          status: "0",
          userId: 32,
          boxInfo: {
            boxId: 3,
            boxImg01: "",
            boxImg02: "",
            number: 3,
            ornaments: [
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 28,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBNCB8IEFzaWltb3YgKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A4 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3858,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 888.49,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 22,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3606,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 784.18,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 30,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoV2VsbC1Xb3JuKQ==.png",
                itemName: "AWP | 二西莫夫 (破损不堪)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 4177,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 557.22,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 26,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoQmF0dGxlLVNjYXJyZWQp.png",
                itemName: "AWP | 二西莫夫 (战痕累累)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3854,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 429.61,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 23,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChNaW5pbWFsIFdlYXIp.png",
                itemName: "AK-47 | 二西莫夫 (略有磨损)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3713,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 271.31,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 29,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDkwIHwgQXNpaW1vdiAoTWluaW1hbCBXZWFyKQ==.png",
                itemName: "P90 | 二西莫夫 (略有磨损)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 1985,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 98.74,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 25,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDkwIHwgQXNpaW1vdiAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "P90 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 1912,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 47.23,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 24,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDI1MCB8IEFzaWltb3YgKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "P250 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 9059,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 17.69,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 27,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDI1MCB8IEFzaWltb3YgKEJhdHRsZS1TY2FycmVkKQ==.png",
                itemName: "P250 | 二西莫夫 (战痕累累)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 9270,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 9.58,
              },
            ],
          },
        },
      ],
      speed: 6,
      //---------------------------------
      boxItem: 0,
      // debug 目标位置
      resLocation: {},
    };
  },
  components: {
    lotteryRounds,
  },
  methods: {
    resLocationChange(L) {
      //console.log("test01位置改变 "+JSON.stringify(L))
      this.resLocation = L;
    },
    onRun(newv) {
      console.log("speed=" + newv);
    },
  },
};
</script>

<style lang="scss" scoped>
.lotteryRounds {
  position: relative;
  height: 50%;
  border: 1px solid white;
}

.boxItem {
  transition: all 2s linear;
}

.boxItem:hover {
  transform: translate(0px, 200px);
}

.list_animate {
  // transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0.1s;
  // transform: translateY(-1947px);
}

.list_animate:hover {
  // transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0.1s;
  // transform: translateY(-1947px);
}

.list_box {
  width: 92px;
  height: 98px;
  background-size: 100% 100%;
}
</style>
